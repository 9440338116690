import { Transition } from "@headlessui/react";
import { useRouter } from "next/router";
import { removeTokenCookies } from "../../../../lib/cookie";
import ThemeToggle from "../../../Inputs/ThemeToggle/ThemeToggle";
import { UserCircleIcon } from "@heroicons/react/outline";
import { removeLanguageInLocalStorage } from "../../../../helpers/languageHelper";
import { useAnalytics } from "../../../../contexts/Segment/Segment";

function AccountDropdown({ isOpen, setOpen }) {
  const router = useRouter();
  const analytics = useAnalytics();

  return (
    <div className="ml-4 relative flex-shrink-0">
      <div>
        <button
          className="rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 text-gray-900 dark:text-gray-200"
          id="user-menu"
          aria-haspopup="true"
          onClick={() => setOpen(!isOpen)}
        >
          <UserCircleIcon className="h-8 w-8 rounded-full" />
          {/* <img
            className="h-8 w-8 rounded-full"
            src={
              user?.picture ??
              "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
            }
            alt=""
          /> */}
        </button>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div
          className="z-20 origin-top-right absolute right-0 mt-2 w-60 rounded-md shadow-lg overflow-hidden bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          {/* {user?.name && (
            <div
              className="block px-4 pt-2 text-sm text-gray-700 dark:text-gray-200 font-bold "
              role="menuitem"
            >
              {user.name}
            </div>
          )}

          <div
            className="block px-4 pb-2 text-sm text-gray-700 dark:text-gray-200 border-b border-gray-200 dark:border-gray-700"
            role="menuitem"
          >
            {user?.email}
          </div> */}

          <div
            className="flex px-4 py-2 text-sm text-gray-700 dark:text-gray-200 align-middle justify-between "
            role="menuitem"
          >
            <div>Dark mode</div>
            <ThemeToggle />
          </div>
          {/* <a
            href="/account"
            className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
            role="menuitem"
          >
            Your Profile
          </a>*/}
          <a
            href="/settings"
            className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
            role="menuitem"
          >
            Settings
          </a>
          {/* <a
            href="/api/user/create-customer-portal-session"
            className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
            role="menuitem"
          >
            Subscription and Billing
          </a> */}
          <a
            href="mailto:brian@tapforservice.com"
            className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700"
            role="menuitem"
          >
            Contact Support
          </a>
          <a
            onClick={() => {
              analytics.reset();
              removeLanguageInLocalStorage();
              removeTokenCookies();
              router.push("/login");
            }}
            className="block px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer"
            role="menuitem"
          >
            Sign out
          </a>
        </div>
      </Transition>
    </div>
  );
}

export default AccountDropdown;
