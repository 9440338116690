import React from "react";
import DarkModeToggle from "react-dark-mode-toggle";
import { ThemeContext } from "../../../contexts/ThemeContext/ThemeContext";

const ThemeToggle = () => {
  const { theme, setTheme } = React.useContext(ThemeContext);
  return (
    <DarkModeToggle
      onChange={(theme) => setTheme(theme ? "dark" : "light")}
      checked={theme === "dark"}
      size={40}
    />
  );
};

export default ThemeToggle;
