const FormHeader = ({ title, description }) => {
  return (
    <div className="mb-5">
      <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">
        {title}
      </h3>
      {description && (
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
          {description}
        </p>
      )}
    </div>
  );
};

export default FormHeader;
