import { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon, SearchIcon } from "@heroicons/react/outline";
import { useRouter } from "next/router";
import Link from "next/link";
import AccountDropdown from "../NavigationBar/AccountDropdown/AccountDropdown";
import Modal from "../../Modals/Modal/Modal";
import SearchForm from "../../Forms/SearchForm/SearchForm";
import links from "../../../data/navigation";
import NavigationSearch from "../NavigationBar/NavigationSearch/NavigationSearch";

export default function SecondaryTopNav({ children }) {
  const router = useRouter();
  const [isAccountMenuOpen, setAccountMenuOpen] = useState(false);
  const [isSearchModalOpen, setSearchModalOpen] = useState(false);

  return (
    <>
      <Modal
        show={isSearchModalOpen}
        closeModal={() => setSearchModalOpen(false)}
      >
        <SearchForm />
      </Modal>
      <Disclosure as="nav" className="bg-white shadow dark:bg-primary-900">
        {({ open }) => (
          <>
            <div className="px-2 sm:pl-0 sm:pr-6 lg:pr-8">
              <div className="relative flex justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 dark:hover:text-gray-200 hover:bg-gray-100 dark:hover:bg-primary-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                    {children}
                  </div>
                </div>

                <div className="flex items-center pr-2 sm:ml-6 sm:pr-0">
                  {/* Search button (mobile only) */}
                  <div className="sm:hidden">
                    <button
                      type="button"
                      onClick={() => {
                        setSearchModalOpen(true);
                      }}
                      className="hidden sm:block bg-white dark:bg-primary-800 p-1 rounded-full text-gray-400 dark:text-primary-200 dark:hover:text-white hover:text-gray-500 dark:hover:bg-primary-900  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <span className="sr-only">View notifications</span>
                      <SearchIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  {/* Fake search text input */}
                  <NavigationSearch />
                  {/* Account Dropdown */}
                  <AccountDropdown
                    isOpen={isAccountMenuOpen}
                    setOpen={setAccountMenuOpen}
                  />
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 pb-4 space-y-1">
                {links.map((link) => (
                  <Link href={link.href} key={link.href}>
                    <span
                      className={`${
                        (router?.pathname ?? "") == link.href
                          ? "bg-primary-50 border-primary-500 text-primary-700 dark:bg-primary-700 dark:border-primary-600 dark:text-primary-100"
                          : "border-transparent text-gray-500 dark:text-gray-200 hover:bg-gray-50 dark:hover:bg-primary-900 hover:border-gray-300 dark:hover:border-gray-900 hover:text-gray-700"
                      } block pl-3 pr-4 py-2 border-l-4 text-base font-medium cursor-pointer`}
                    >
                      {link.label}
                    </span>
                  </Link>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}
