import AuthenticatedSideNav from "../../Navigation/AuthenticatedSideNav/AuthenticatedSideNav";
import SecondaryTopNav from "../../Navigation/SecondaryTopNav/SecondaryTopNav";

const AuthenticatedSideLayout = ({ children, disableMaxWidth = false }) => {
  return (
    <div className="bg-gray-100 dark:bg-gray-900 min-h-screen relative">
      <AuthenticatedSideNav />
      <div>
        <SecondaryTopNav />
        <div className="pb-10 py-6 lg:py-8 mx-4 sm:mx-6 lg:mx-12 sm:pl-[150px] lg:pl-[250px]">
          <main className={`mx-auto ${!disableMaxWidth && "max-w-6xl"}`}>
            {children}
          </main>
        </div>
      </div>
    </div>
  );
};

export default AuthenticatedSideLayout;
