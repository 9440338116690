import React from "react";
import ModalContainer from "./ModalContainer/ModalContainer";

const Modal = ({ children, closeModal, show }) => {
  return (
    <ModalContainer show={show} closeModal={closeModal}>
      {children}
    </ModalContainer>
  );
};

export default Modal;
