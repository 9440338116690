import Footer from "../../Navigation/Footer/Footer";
import PublicTopNav from "../../Navigation/PublicTopNav/PublicTopNav";

const PublicLayout = ({ children }) => {
  return (
    <>
      <PublicTopNav />
      <div className="max-w-7xl mx-auto pb-10 py-6 lg:py-12 lg:px-8">
        <main className="mx-auto">{children}</main>
      </div>
      <Footer />
    </>
  );
};

export default PublicLayout;
