import React, { useState } from "react";
import PrimaryButton from "../../Buttons/PrimaryButton/PrimaryButton";

const NoDataMessage = ({
  children,
  buttonLabel,
  buttonOnClick,
  minHeight = "70vh",
  className,
}) => {
  return (
    <div
      className="flex flex-col justify-center text-gray-400 bg-gray-50 dark:bg-gray-800 text-center"
      style={{ minHeight }}
    >
      <div className={className}>{children}</div>
      {buttonLabel && (
        <div className="mt-5">
          <PrimaryButton size="md" onClick={buttonOnClick}>
            {buttonLabel}
          </PrimaryButton>
        </div>
      )}
    </div>
  );
};

export default NoDataMessage;
