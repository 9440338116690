import React, { useState } from "react";
import Modal from "../../../Modals/Modal/Modal";
import SearchForm from "../../../Forms/SearchForm/SearchForm";
import { useHotkeys } from "react-hotkeys-hook";
import SearchButton from "../SearchButton/SearchButton";

function NavigationSearch() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  useHotkeys("cmd+k", () => {
    setIsModalOpen(true);
  });

  return (
    <>
      <Modal show={isModalOpen} closeModal={() => setIsModalOpen(false)}>
        <SearchForm />
      </Modal>
      <div className="sm:hidden">
        <SearchButton onClick={() => setIsModalOpen(true)} />
      </div>
      <div className="max-w-lg w-full hidden sm:block lg:max-w-xs">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 px-3 flex items-center pointer-events-none justify-between w-full">
            <svg
              className="h-5 w-5 text-gray-500 dark:text-gray-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
            <span className="hidden sm:block text-gray-400 text-sm leading-5 py-0.5 px-1.5 border border-gray-400 rounded-md opacity-80">
              <span className="font-sans">⌘</span>
              <span className="font-sans">K</span>
            </span>
          </div>
          <input
            id="search"
            name="search"
            className={`block w-full pl-10 pr-3 py-2 border border-gray-300 dark:border-gray-500 rounded-md leading-5 text-gray-500 bg-white dark:bg-gray-800 placeholder-gray-500 dark:placeholder-gray-200 focus:placeholder-gray-500
                dark:text-white focus:outline-none  focus:ring-1 focus:ring-grey-500 focus:border-grey-500 sm:text-sm`}
            placeholder="Search"
            type="search"
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      </div>
    </>
  );
}

export default NavigationSearch;
