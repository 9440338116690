// import algoliasearch from "algoliasearch/lite";
// import { InstantSearch, Hits, SearchBox } from "react-instantsearch-dom";
// import ReferenceHit from "./ReferenceHit/ReferenceHit";
// import SearchTextInput from "../../Inputs/SearchTextInput/SearchTextInput";

import FormHeader from "../../Forms/FormHeader/FormHeader";
import NoDataMessage from "../../Common/NoDataMessage/NoDataMessage";

const SearchForm = () => {
  return (
    <div className="ais-InstantSearch p-6">
      <FormHeader title="Search" />
      <div className="pt-3">
        <NoDataMessage minHeight="300px">Coming soon!</NoDataMessage>
        {/* {searchApiKey && (
          <InstantSearch indexName="references" searchClient={searchClient}>
            <SearchTextInput />
            <div className="pt-5">
              <Hits hitComponent={ReferenceHit} />
            </div>
          </InstantSearch>
        )} */}
      </div>
    </div>
  );
};

export default SearchForm;
