import Link from "next/link";
import Logo from "../../Common/Logo/Logo";
import links, { bottomLinks } from "../../../data/navigation";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { getCurrentUserId } from "../../../lib/cookie";
import { useAnalytics } from "../../../contexts/Segment/Segment";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const AuthenticatedSideNav = () => {
  const router = useRouter();
  const analytics = useAnalytics();

  useEffect(() => {
    if (typeof window !== `undefined`) {
      const loggedInUserId = getCurrentUserId();

      if (loggedInUserId) {
        analytics.identify(loggedInUserId);
      }
    }
  }, []);

  return (
    <div className="min-h-0 bg-primary-700 dark:bg-primary-800 fixed h-screen w-[150px] lg:w-[250px] hidden sm:block ">
      <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto justify-between h-full">
        <div>
          <div className="flex items-center flex-shrink-0 px-4">
            <Logo themeOverride="dark" width={"100%"} />
          </div>
          <nav className="mt-5 flex-1 px-2 space-y-1" aria-label="Sidebar">
            {links.map((item) => {
              let activeLink =
                (router?.pathname ?? "").startsWith(item.href) &&
                !item.childLinks;

              // edge case: dashboard page
              if (item.href == "/" && (router?.pathname ?? "") != "/")
                activeLink = false;

              return (
                <div key={item.href}>
                  <Link key={item.label} href={item.href}>
                    <a
                      className={classNames(
                        activeLink
                          ? "bg-primary-800 dark:bg-primary-900 text-white"
                          : "text-primary-100 hover:bg-primary-600 dark:hover:bg-primary-700 hover:bg-opacity-75",
                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                      )}
                    >
                      <item.icon
                        className="mr-3 flex-shrink-0 h-6 w-6 text-primary-300"
                        aria-hidden="true"
                      />
                      <span className="flex-1">{item.label}</span>
                      {item.count ? (
                        <span
                          className={classNames(
                            item.current ? "bg-primary-600" : "bg-primary-800",
                            "ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full"
                          )}
                        >
                          {item.count}
                        </span>
                      ) : null}
                    </a>
                  </Link>
                  {item.childLinks &&
                    (router?.pathname ?? "") == item.href &&
                    item.childLinks.map((subItem) => (
                      <Link key={subItem.label} href={subItem.href}>
                        <a
                          className={classNames(
                            (router?.pathname ?? "") == subItem.href
                              ? "bg-primary-800 text-white"
                              : "text-primary-100 hover:bg-primary-600 hover:bg-opacity-75",
                            "group flex items-center px-2 py-2 text-sm font-medium rounded-md pl-4 ml-4"
                          )}
                        >
                          <span className="flex-1">{subItem.label}</span>
                          {subItem.count ? (
                            <span
                              className={classNames(
                                item.current
                                  ? "bg-primary-600"
                                  : "bg-primary-800",
                                "ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full"
                              )}
                            >
                              {subItem.count}
                            </span>
                          ) : null}
                        </a>
                      </Link>
                    ))}
                </div>
              );
            })}
          </nav>
        </div>
        <nav className="mt-5 px-2 space-y-1" aria-label="Sidebar">
          {bottomLinks.map((item) => (
            <>
              <Link key={item.label} href={item.href}>
                <a
                  className={classNames(
                    (router?.pathname ?? "") == item.href && !item.childLinks
                      ? "bg-primary-800 text-white"
                      : "text-primary-100 hover:bg-primary-600 hover:bg-opacity-75",
                    "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                  )}
                >
                  <item.icon
                    className="mr-3 flex-shrink-0 h-6 w-6 text-primary-300"
                    aria-hidden="true"
                  />
                  <span className="flex-1">{item.label}</span>
                  {item.count ? (
                    <span
                      className={classNames(
                        item.current ? "bg-primary-600" : "bg-primary-800",
                        "ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full"
                      )}
                    >
                      {item.count}
                    </span>
                  ) : null}
                </a>
              </Link>
              {item.childLinks &&
                (router?.pathname ?? "") == item.href &&
                item.childLinks.map((subItem) => (
                  <Link key={subItem.label} href={subItem.href}>
                    <a
                      className={classNames(
                        (router?.pathname ?? "") == subItem.href
                          ? "bg-primary-800 text-white"
                          : "text-primary-100 hover:bg-primary-600 hover:bg-opacity-75",
                        "group flex items-center px-2 py-2 text-sm font-medium rounded-md pl-4 ml-4"
                      )}
                    >
                      <span className="flex-1">{subItem.label}</span>
                      {subItem.count ? (
                        <span
                          className={classNames(
                            item.current ? "bg-primary-600" : "bg-primary-800",
                            "ml-3 inline-block py-0.5 px-3 text-xs font-medium rounded-full"
                          )}
                        >
                          {subItem.count}
                        </span>
                      ) : null}
                    </a>
                  </Link>
                ))}
            </>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default AuthenticatedSideNav;
