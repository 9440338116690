import { useEffect, useState } from "react";
import { getActiveAuthToken } from "../../../lib/cookie";
import PrimaryButton from "../../Buttons/PrimaryButton/PrimaryButton";
import SecondaryButton from "../../Buttons/SecondaryButton/SecondaryButton";
import AuthenticatedSideLayout from "../../Layouts/AuthenticatedSideLayout/AuthenticatedSideLayout";
import PublicLayout from "../../Layouts/PublicLayout/PublicLayout";

const FullErrorPage = ({ resetError, title, message }) => {
  const [authed, setAuthed] = useState(false);

  useEffect(() => {
    const isLoggedIn = !!getActiveAuthToken();
    setAuthed(isLoggedIn);
  }, []);

  const mainComponent = (
    <div className="bg-white dark:bg-gray-800 min-h-[700px] px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="max-w-max mx-auto text-center">
        <h1 className="text-4xl font-extrabold text-gray-900 dark:text-gray-100 tracking-tight sm:text-5xl">
          {title ? title : "Something is wrong."}
        </h1>
        <p className="mt-1 text-base text-gray-500 dark:text-gray-300 max-w-[500px]">
          {message
            ? message
            : "You have encountered an error, and we have automatically notified the support team. Please reach out if you need immediate help or try again."}
        </p>
        <div className="mt-10 flex space-x-3 justify-center">
          {resetError ? (
            <PrimaryButton
              onClick={() => {
                resetError();
              }}
            >
              Refresh Page
            </PrimaryButton>
          ) : (
            <PrimaryButton href="/">Go to home page</PrimaryButton>
          )}
          <SecondaryButton href="mailto:brian@tapforservice.com">
            Contact support
          </SecondaryButton>
        </div>
      </div>
    </div>
  );

  return authed ? (
    <AuthenticatedSideLayout>{mainComponent}</AuthenticatedSideLayout>
  ) : (
    <PublicLayout>{mainComponent}</PublicLayout>
  );
};

export default FullErrorPage;
